import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import Routes from './Routes';
import { Suspense } from 'react';
import Loading from 'components/Loading';
import { I18nextProvider } from 'react-i18next';
import i18next from './providers/i18next.js';
import { ThemeProvider } from './context/ThemeContext.jsx';

function App() {
  const browserHistory = createBrowserHistory();

  return (
    <ThemeProvider>
      <I18nextProvider i18n={i18next}>
        {/* <TypiChat /> */}
        <Router history={browserHistory}>
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </Router>
      </I18nextProvider>
    </ThemeProvider>
   
  );
}

export default App;
